.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #116CFF;
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  position: fixed;
  z-index: 1000;
}

.header-logo {
  width: 15%;
  margin-left: 1%;
}

.menu {
  display: flex;
  flex-direction: row;
  width: 20%;
  display: flex;
  justify-content: space-between;
  margin-right: 2%
}

.menu-mobile{
  display: none;
}

.intro {
  /* width: 100%; */
  padding-top: 90px;
  background-color: #116CFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* min-height: 560px; */
  min-height: 85vh;
}

.intro-title-container {
  display: flex;
  flex-direction: column;
  text-align: start;
  justify-content: start;
  font-size: 20px;
  max-width: 500px;
  margin-bottom: 30px;
}

.intro-left {
  width: 50%;
  padding-left: 7%;
}

.intro-title {
  margin-bottom: 0px;
  color: white;
}

.intro-subtitle {
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: start;
  text-align: start;
  line-height: 25px;
  margin-bottom: 30px;
  max-width: 350px;
}

.button-intro {
  flex: 1;  /* Mengatur tombol untuk mengambil ruang yang sama dalam container */
  height: 50px;  /* Tinggi tetap untuk semua tombol */
  border-width: 1px;
  border-style: solid;
  border-color: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 5px; /* Memberikan jarak horizontal antar tombol */
}

.button-intro-2{
  flex: 1;  /* Mengatur tombol untuk mengambil ruang yang sama dalam container */
  height: 50px;  /* Tinggi tetap untuk semua tombol */
  border-width: 1px;
  border-style: solid;
  border-color: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 5px; /* Memberikan jarak horizontal antar tombol */
  margin-top: 20px;
}


.container-button-intro {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.intro-right {
  width: 50%;
  padding-right: 7%;
  position: relative;
}

.img-intro {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 90%;
  z-index: 0;
}

.label-1 {
  width: 30%;
  padding-left: 1%;
  padding-right: 1%;
  border-radius: 20px;
  background-color: #FFCF2D;
  z-index: 100;
  position: absolute;
  bottom: 20;
  top: 5%;
  right: 6%;
  min-width: 250px;
}

.label-2 {
  width: 30%;
  padding-left: 1%;
  padding-right: 1%;
  border-radius: 20px;
  background-color: #FFCF2D;
  z-index: 100;
  position: absolute;
  bottom: 20%;
  right: 50%;
  min-width: 250px;
}

.container-reason {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.reason-left {
  width: 45%;
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 105px;
  position: relative;
}

.reason-left img {
  position: absolute;
  top: 0%;
  height: 500px;
  width: 150%;
  left: 0%;
}

.reason-left div {
  width: 80%;
  height: 500px;
  background-color: #FF8320;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.reason-right {
  width: 55%;
  text-align: left;
  margin-top: 70px;
  padding-right: 100px;
  display: flex;
  flex-direction: column;
}

.reason-right h3 {
  color: #116CFF;
  font-size: 40px;
  margin-bottom: 0px;
  line-height: 10px;
  text-align: left;
}

.reason-right-desc {
  text-align: left;
  font-size: 25px;
  line-height: 40px;
}

.reason-item-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between
}

.reason-container-left{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 10px
}

.reason-container-right{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.reason-item-desc {
  font-size: 25px;
}

.icon-reason {
  width: 60px;
  height: 60px;
}

.univercities-container {
  margin-bottom: 140px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.univercities-title {
  font-size: 40px;
  color: #116CFF
}

.univercities-desc {
  padding-left: 12%;
  padding-right: 12%;
  font-size: 23px;
  margin-bottom: 60px;
}

.univercities-images {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: scroll;
  padding-bottom: 10px;
}

.image-univercity {
  height: 200px;
  width: 200px;
  margin-left: 10px;
  margin-right: 10px;
}

.packages-container {
  margin-bottom: 90px;
}

.package-title {
  font-size: 40px;
  color: #116CFF
}

.package-desc {
  padding-left: 18%;
  padding-right: 18%;
  font-size: 23px;
  margin-bottom: 60px;
}

.select-offline-online {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.select-offline-online p {
  font-size: 25px;
  color: #ffffff;
  line-height: 0px;
}

.select-offline-online div:nth-child(1) {
  width: 20%;
  border-radius: 40px;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #116CFF;
}

.select-offline-online div:nth-child(2) {
  width: 20%;
  border-radius: 40px;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #FF8320;
}

.select-educational-level {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.select-educational-level-left-text {
  margin-right: 20px;
  color: #116CFF;
  text-align: start
}

.button-grade {
  width: 50px;
  margin-right: 10px;
  border-width: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 5px;
  cursor: pointer
}

.package-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: scroll;
  padding-bottom: 10px;
}

.package-list-mobile {
  width: 100%;
  display: none;
  gap: 10px;
}


.package-item {
  border-width: 1;
  /* border-color: lightgrey; */
  border-style: solid;
  border-radius: 8px;
  width: 300px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  margin-left: 5px;
  margin-right: 5px;
  height: fit-content;
}

.best-seller-label{
  background-color: #116CFF;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.best-seller-label p{
  margin-right: 5px;
}

.package-item-inner{
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.price-item-container {
  margin-top: 20px;
  font-weight: bold;
}

.price-item {
  font-size: 30px;
}

.price-grand-price {
  font-size: 30px;
  color: #116CFF
}

.button-daftar-sekarang {
  margin-top: 20px;
  margin-bottom: 40px;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: #116CFF;
  background-color: #ffffff;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 5px;
  cursor: pointer;
}

.apa-yang-kamu-dapatkan {
  margin-bottom: 10px;
}

.spec-item {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center
}

.spec-item-checklist{
  width: 25px; 
  display: flex;
  align-items: center;
  justify-content: center
}

.spec-item-desc {
  text-align: start;
  max-lines: 2;
  margin-left: 10px;
  margin-bottom: 12;
}


.testimoni-container {
  margin-bottom: 150px;
  width: 100%
}

.testimoni-container-title {
  font-size: 40px;
  color: #116CFF;
  margin-bottom: 40px;
}

.testimoni-container-images {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
}

.arrow-icon {
  width: 35px;
  height: 62px;
  cursor: pointer;
}

.testimoni-user-image {
  height: 350px;
  width: 350px;
  background-color: #116CFF;
  border-radius: 10px
}

.testimoni-user-data {
  padding-left: 15%;
  padding-right: 15%;
}

.testimoni-user-name {
  font-size: 40px;
  color: #116CFF;
  margin-bottom: 60px
}

.testimoni-user-school {
  font-size: 22px;
  margin-bottom: 80px;
}

.testimoni-user-desc {
  font-size: 22px
}

.consultation-box {
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 20px;
  border-radius: 20;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #116CFF;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  position: relative;
  margin-bottom: 100px;
}

.contact-box {
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 50px;
}

.consultation-box-left {
  text-align: left;
  width: 50%;
  margin-bottom: 40px;
}

.button-hubungi-sekarang {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 5px;
  height: 30;
  background-color: '#fff';
  border-width: 0;
  color: #116CFF;
  font-weight: bold;
  background-color: white;
  font-size: 18px;
  cursor: pointer;
}

.consultation-now-desc {
  margin-bottom: 40px;
  line-height: 20px;
  max-width: 65%;
}

.consultation-box-right {
  width: 50%;
}

.consultation-box-right img {
  position: absolute;
  bottom: 0%;
  right: 5%;
  height: 90%;
  width: 60%;
}

.contact-us-right{
  display: flex;
  flex-direction: row;
}

.contact-us-right-product{
  text-align: start;
  margin-right: 10%;
}

.contact-us-right-product-title{
  margin-bottom: 36px;
}

.contact-us-right-product h3{
  font-size: 20px;
}

.contact-us-right-contacts{
  text-align: start
}

.contact-us-right-contacts h3{
  font-size: 20px;
}

.contact-us-right-contacts-list div{
    
}

.container-icon-contact-us{
  text-align: start;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: -10px;
}

.container-icon-contact-us p{
  margin-bottom: 20px;
}

.icon-contact-us-email{
  margin-right: 10px;
  width: 20px;
  height: 15px;
}

.icon-contact-us-phone{
  margin-right: 10px;
  width: 20px;
  height: 19px;
}

.icon-contact-us-location{
  margin-right: 10px;
  width: 16px;
  height: 19px;
}

.bottom-logo {
  width: 20%
}

.bottom-social-media-icon {
  margin-right: 15px;
  cursor: pointer;
}

.container-navigasi-mobile{
  display: none;
}


@media only screen and (max-width: 600px) {
  body {
    width: 100%;
  }

  .App-header {
    background-color: #116CFF;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    position: fixed;
    z-index: 1000;
  }

  .header-logo {
    width: 30%;
    margin-left: 5%;
  }

  .menu {
    display: none;
  }

  .intro {
    /* width: 100%; */
    padding-top: 90px;
    background-color: #116CFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 7%;
    padding-right: 7%;
  }

  .intro-left {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px;
  }

  .intro-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 350px;
    margin-bottom: 0px;
  }

  .intro-title {
    margin-bottom: 0px;
    color: white;
    text-align: center;
    font-size: 22px;
    line-height: 25px;
  }

  .intro-subtitle {
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: start;
    text-align: start;
    margin-bottom: 30px;
    font-size: 15px;
    line-height: 20px;
    max-width: 300px;
  }

  .button-intro {
    width: 200px;
    height: 40px;
    border-width: 1px;
    border-style: solid;
    border-color: #ffffff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .button-intro-2{
    width: 200px;
    height: 40px;
    border-width: 1px;
    border-style: solid;
    border-color: #ffffff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .container-button-intro {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 30px
  }

  .intro-right {
    width: 100%;
    position: relative;
    min-height: 400px;
    padding: 0px;
  }

  .img-intro {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 80%;
    width: 100%;
    z-index: 0;
  }

  .label-1 {
    width: 30%;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 15px;
    background-color: #FFCF2D;
    z-index: 100;
    position: absolute;
    bottom: 20;
    top: 20%;
    right: 6%;
    min-width: 150px;
    font-size: 10px;
  }

  .label-2 {
    width: 30%;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 15px;
    background-color: #FFCF2D;
    z-index: 100;
    position: absolute;
    bottom: 20%;
    right: 50%;
    min-width: 150px;
    font-size: 10px;
  }

  .container-reason {
    /*  */
  }

  .reason-left {
    width: 100%;
    height: 400px;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 105px;
    position: relative;
  }

  .reason-left img {
    position: absolute;
    top: 0%;
    height: 400px;
    width: 100%;
    left: 0%;
  }

  .reason-left div {
    width: 70%;
    height: 500px;
    background-color: #FF8320;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .reason-right {
    width: 100%;
    text-align: left;
    margin-top: 10px;
    padding-right: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  .reason-right h3 {
    color: #116CFF;
    font-size: 20px;
    margin-bottom: 15px;
    line-height: 10px;
    text-align: center;
  }

  .reason-right-title{
    margin-bottom: -5px;
  }

  .reason-right-desc {
    text-align: center;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .reason-item-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .reason-container-left{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 50%;
    margin-right: 10px;
  }

  .reason-container-right{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    max-width: 50%;
    width: auto
  }

  .icon-reason {
    width: 30px;
    height: 30px;
  }

  .reason-item-desc {
    font-size: 10px;
  }

  .univercities-container {
    margin-bottom: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
  }

  .univercities-title {
    font-size: 20px;
    color: #116CFF;
    margin-bottom: 5px;
  }

  .univercities-desc {
    padding-left: 5%;
    padding-right: 5%;
    font-size: 13px;
    margin-bottom: 60px;
    line-height: 20px;
  }

  .univercities-images {
    width: 100%;
    /* padding-left: 5%;
    padding-right: 5%; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .image-univercity {
    height: 56px;
    width: 56px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .packages-container {
    margin-bottom: 100px;
  }

  .package-title {
    font-size: 20px;
    color: #116CFF
  }

  .package-desc {
    padding-left: 5%;
    padding-right: 5%;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 60px
  }

  .select-offline-online {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

  .select-offline-online p {
    font-size: 20px;
    color: #ffffff;
    line-height: 0px;
  }

  .select-offline-online div:nth-child(1) {
    width: 45%;
    border-radius: 40px;
    padding-top: 2px;
    padding-bottom: 2px;
    background-color: #116CFF;
  }

  .select-offline-online div:nth-child(2) {
    width: 45%;
    border-radius: 40px;
    padding-top: 2px;
    padding-bottom: 2px;
    background-color: #FF8320;
  }

  .select-educational-level {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .select-educational-level-left-text {
    margin-right: 5px;
    color: #116CFF;
    text-align: start;
    font-size: 10px;
  }

  .button-grade {
    width: 50px;
    margin-right: 5px;
    border-width: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 5px;
    cursor: pointer
  }

  .package-list {
    display: none;
  }

  .package-list-mobile {
    width: 100%;
    display: block;
    /* gap: 5px; */
  }

  .package-item {
    /* border-width: 1;
    border-color: lightgrey;
    border-style: solid; */
    border-radius: 8px;
    /* width: 40%; */
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin-left: 5px;
    margin-right: 5px;
    height: fit-content;
  }

  .package-item-inner{
    /* width: 100%; */
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .price-item-container {
    margin-top: 10px;
    font-size: 32px;
  }

  .price-item {
    font-size: 17px;
    font-weight: bold;
  }

  .price-grand-price {
    font-size: 17px;
    color: #116CFF
  }

  .button-daftar-sekarang {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: #116CFF;
    color: #116CFF;
    background-color: #ffffff;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 5px;
    cursor: pointer;
  }

  .apa-yang-kamu-dapatkan {
    margin-bottom: 5px;
  }

  .spec-item {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 0px;
  }

  .spec-item-checklist{
    width: 20px; 
    display: flex;
    align-items: center;
    justify-content: center
  }

  .spec-item-desc {
    text-align: start;
    max-lines: 2;
    margin-left: 10px;
    margin-bottom: 0px;
    font-size: 12px;
  }

  .testimoni-container {
    margin-bottom: 50px;
    width: 100%
  }

  .testimoni-container-title {
    font-size: 25px;
    color: #116CFF;
    margin-bottom: 40px;
  }

  .testimoni-container-images {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
  }

  .arrow-icon {
    width: 15px;
    height: 32px;
    cursor: pointer;
  }

  .testimoni-user-image {
    height: 160px;
    width: 160px;
    background-color: #116CFF;
    border-radius: 10px
  }

  .testimoni-user-data {
    padding-left: 5%;
    padding-right: 5%;
  }

  .testimoni-user-name {
    font-size: 25px;
    color: #116CFF;
    margin-bottom: 10px
  }

  .testimoni-user-school {
    font-size: 17px;
    margin-bottom: 20px;
  }

  .testimoni-user-desc {
    font-size: 14.5px
  }

  .contact-us {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .container-consultation-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .consultation-box {
    margin-left: 5%;
    margin-right: 5%;
    width: 100%;
    border-radius: 20px;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #116CFF;
    color: #ffffff;
    display: flex;
    align-self: center;
    flex-direction: row;
    position: relative;
    margin-bottom: 100px;
    align-self: center;
    font-size: 10px;
    height: 100px;
  }

  .consultation-box-left {
    text-align: left;
    width: 50%;
    margin-bottom: 40px;
  }

  .consultation-box-left h1 {
    font-size: 15px;
  }

  .consultation-now-desc {
    margin-bottom: 8px;
    font-size: 7px;
    line-height: 8px;
    max-width: 95%;
  }

  .button-hubungi-sekarang {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    height: 20;
    background-color: '#fff';
    border-width: 0;
    color: #116CFF;
    font-weight: bold;
    background-color: white;
    font-size: 10px;
    cursor: pointer;
  }

  .consultation-box-right {
    width: 50%;
  }

  .consultation-box-right img {
    position: absolute;
    bottom: 0%;
    right: 5%;
    height: 90%;
    width: 60%;
  }

  .bottom-logo {
    width: 50%
  }

  .bottom-social-media-icon {
    margin-right: 5px;
    cursor: pointer;
  }

  .contact-box {
    display: flex;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 50px;
    font-size: 10px;
  }

  .contact-us-right{
    display: flex;
    flex-direction: row;
  }

  .contact-us-right-product{
    text-align: start;
    margin-right: 10%;
  }  

  .contact-us-right-product-title{
    margin-bottom: 0px;
  }
  

  .contact-us-right-product h3{
    font-size: 9px;
  }

  .contact-us-right-product p{
    font-size: 7px;
  }

  .contact-us-right-contacts{
    text-align: start
  }

  .contact-us-right-contacts-title{
    margin-bottom: -8px;    
  }

  .contact-us-right-contacts h3{
    font-size: 9px;
  }  

  .contact-us-right-contacts p{
    font-size: 7px;
    margin-bottom: 15px;
  }  

  .container-icon-contact-us{
    text-align: start;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: -5px;
    padding: 0px;
  }

  .container-icon-contact-us p{
    margin-bottom: 7px;
  }
  
  .icon-contact-us-email{
    margin-right: 5px;
    width: 10px;
    height: 7.5px;
    margin-bottom: 0px;
  }
  
  .icon-contact-us-phone{
    margin-right: 5px;
    width: 10px;
    height: 9.5px;
    margin-bottom: 0px;
  }
  
  .icon-contact-us-location{
    margin-right: 5px;
    width: 8px;
    height: 9.5px;
    margin-bottom: 0px;
  }

  .menu-mobile{
    display: block;
    margin-right: 15px;
  }

  .container-navigasi-mobile{
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1200;
    height: 100%;
    background-color: transparent;
  }

  .navigasi-mobile{
    display: block;
    /* margin-top: -8px; */
    height: 80%;
    float: right;
    width: 100%;
  }
  
  .navigasi-mobile-inner{
    float: right;
    margin-top: 10px;
    margin-right: 10px;
    padding-top:30px;
    padding-bottom:30px;
    /* height: 55%; */
    width: 40%;
    background-color: white;
    /* shadow */
    padding: 2px 2px 4px 2px;
    border: 1px solid #BFBFBF;
    box-shadow: 2px 2px 1px #aaaaaa;
  }

  .dropmenu{
    width: 100%;
    display: flex;
    float: right;
    flex-direction: column;
    align-items: flex-start;
    list-style-type: none;
  }

  .dropmenu ul{
    padding-top:30px;
    padding-bottom:30px;
  }

  .dropmenu li{
    float:left;
    height:45px;
    width:100;
    padding-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
}

@media only screen and (max-width: 447px) {
  .intro-subtitle {
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: start;
    text-align: start;
    margin-bottom: 30px;
    font-size: 15px;
    line-height: 20px;
    max-width: 281.5px;
  }

  .reason-container-right-inner{
    width: 84%;
  }
  .consultation-box-right img {
    position: absolute;
    bottom: 0%;
    right: 0;
    height: 90%;
    width: 67%;
  }
}